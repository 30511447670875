.card{
    position: relative;
    border-radius: 10px;
    box-shadow: 2px 3px 8px rgb(182, 179, 179);
    margin: 0px 0px 25px;
    width: 46%;
}

.deactive{
    opacity: 0.3;

}

.alert{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    bottom : 0%;
    right: 0%;
}
.alertTxt{
    color: rgb(246, 70, 7);
    transform: rotate(-45deg);
    font-size: 2rem;
}
.selected{
    background-color: rgb(255, 87, 34);
    color: white;
}
.img{
    width: 30px;
    border-radius: 50%;
}

@media screen and (max-width: 500px) {
    .card{
        margin: 0px 0px 25px;
        width: 85%;
    }
}
    