@media (min-height: 400px) and (max-height:600px) {

    .voteBox{
        max-height: 230px;
    }

}
@media (min-height: 601px) and (max-height:700px) {

    .voteBox{
        max-height: 330px;
    }

}
@media (min-height: 701px)  {

    .voteBox{
        max-height: 430px;
    }

}