.card{
    border-radius: 10px;
    box-shadow: 2px 3px 15px rgb(182, 179, 179);
    margin: 0px auto 25px;
    width: 100%;
}
.divider{
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgb(219, 214, 214) !important;
}

.more{
    color : rgb(255, 94, 27)
}

.img{
    width: 30px;
    border-radius: 50%;
}

.mapWrapper{
    margin-top: 15px;
    width: 100% !important;
    height: 200px !important;
    overflow: hidden;
}