.customInput{
    width: 25%;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 4px;
    direction: ltr;
    text-align: center;
}
.customInput:focus {
    border: 1px solid grey;
    text-align: center;
    
}