/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */


/* page header & footer */
table {
    border-collapse: inherit;
}
.page-header{
    margin: 20px auto 20px auto;
}
.page-footer{
    font-size: 10px;
    margin: 20px auto 20px auto;
}

.page-footer a{
    margin: 0px 7px 0px 0px;
}


/* calendar */
.font-eng{
    font-family: Tahoma, 'Segoe UI', Verdana, sans-serif !important;
}

.font-fa{
    font-family: 'Shabnam' !important;
}

.dayoff{
    color: red !important;
}
.dayoffregular{
    background-color: #00ab66 !important;
}
.dayoffregular b{
    color: #FFF;
}
.dayoffoccasion{
    background-color: #ff9800 !important;
}
.dayoffoccasion b{
    color: #FFF;
}
.dayofftody{
    /*border:1px solid rgba(0, 0, 0, 0.69) !important;*/
    background-color: #bdbdbd !important;
}

.tdinfo{
    margin: 2px 0px 2px 0px;
}

.fc-table{
    font-family: 'iranyekan';
    margin: 0 auto;
    direction: rtl;
}

.fc-table caption{
    text-align: right;
}

.fc-table th,
.fc-table td{
    white-space: pre;
    text-align: center;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.fc-table th{
   
    color: #000000;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    font-size: 12px;
    font-weight: 100;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 14.28%;
}

.fc-table td{
    border-radius: 50px;
    /* width: 75px; */
    text-align: -webkit-center;
}

.fc-table  td.selected div{
 background-color:  #ff5722 !important;
}
.fc-table  td.selected div b{
 color:  white !important;
}

.fc-table td.dayoff{
    background-color: #fff;
    color: red !important;
}

.fc-table td b{
    font-size: 16px;
    font-weight: 100;
}



.fc-table td span{
    font-family: 'Tahoma' !important;
    font-size: 9px;
    color: #5b5b5b;
}
.fc-table td div{
    border-radius: 50px;
    /* padding: 5px; */
    width: 35px;
    height: 35px;
    /* margin: 5px; */
}


.fc-table td div:hover{
    background-color: #ff5722 !important;
    cursor: pointer;
    color: white !important;
    -webkit-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
    /*-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
    /*-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
    /*box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
}
.fc-table td div.ali{
    background-color: #05a201;
    cursor: pointer;
    color: white !important;
    -webkit-transition: background-color 1s;
    -o-transition: background-color 1s;
    transition: background-color 1s;
    /*-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
    /*-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
    /*box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
}

.fc-table td:hover span{
    color:#D3D3D3;
}


/* popover settings */
.popover{
    font-family: 'Shabnam';
    text-align: right;
    direction: rtl;
}

.popover-title{
    font-size: 12px;
    font-weight: bold;
    min-width: 200px;
}

.popover-title p{
    font-size: 10px;
    font-weight: normal;
    margin-top: 7px;
    margin-bottom: 5px;
}

.popover-content{
    font-size: 10px;
}

.popover-content ul{
    padding-right: 5px;
}
@media (max-width: 376px) {

    .fc-table th {
        font-size: 9px!important;
    }
}




@media (max-width: 361px) {

    .fc-table th {
        background-color: #ffffff;
        color: #000000;
        border-bottom: 1px solid #d8d8d8;
        border-top: 1px solid #d8d8d8;
        font-size: 8px!important;
        font-weight: 100;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 14.28%;
    }


}
@media (max-width: 321px) {
    .fc-table td {
        border-radius: 50px;
        width: 75px;
        height: 35px!important;
        text-align: -webkit-center;
    }
    .fc-table td div {
        border-radius: 50px;
        padding: 2px 0px;
        width: 29px;
    }
    .fc-table th {
        background-color: #ffffff;
        color: #000000;
        border-bottom: 1px solid #d8d8d8;
        border-top: 1px solid #d8d8d8;
        font-size: 8px!important;
        font-weight: 100;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 14.28%;
    }
}
.notmonth
{
  opacity: 0.2;
}
.today
{
  border-radius: 50%;
  background-color: #ff5722;
  color: #fff!important;
}
.active{
  border-radius: 50%;
  color: #000!important;
}
.myevents
{
  border:3px solid #ff5722;
  border-radius: 50%;
  /* color: #000000!important; */
}
.fri
{
  color: #ff5722;
}

.centerB{
    position: relative;
    top: 4px;
}
