.collapsible {
   
   
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 15px;
  }
  
  

  
  .content {
    /* padding: 0 8px; */
    /* max-height: 0; */
    overflow: hidden;
    transition: max-height 0.99s ease-out;
  }