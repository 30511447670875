.botton{
    width: 70px;
    /* height: 70px; */
    margin: 0 auto;
    border-radius: 8px;
}
.title{
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px; 
}
.subTitle{
    width: 100%;
    font-size: 12px;
    text-align: right;
    color: #757575;
}
.amount{
    font-size: 18px;
    font-weight: 600;
}
.eachCase{
    background-color: #f7f7f7;
    border-radius: 13px;
    align-items: center;
    font-size: 13px;
}