.Modal {
  position: absolute;
  /* z-index: 1001; */
  background-color: white;
  /* width: 90%; */
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px 1px #0000004a;
  padding: 10px;
  top : 0;
  left: 50%;
  /* top: 50%; */
  box-sizing: border-box;
  border-radius: 10px;
  transition: all 0.5s ease-in;
  transform: translate(-50%, -50%);
}
.close {
  width: 5vw;
}
