.customInput{
    width: 25%;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 4px;
}
.customInput:focus {
    border: 1px solid grey;
    text-align: center;
    direction: ltr;
    font-size: 11px;
    
}
.inputS{
    width: 100%;
    border:0;
    text-align: center;
    direction: ltr;
    font-size: 11px;
    
    
    
}