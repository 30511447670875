.imgBack{
    /*max-width: 416px;*/
    /*margin: 0 auto;*/
    /*overflow: hidden;*/
    background-image: url('../../../Assets/myAsset/Group 138@2x.png');
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;


}
/*@media(max-width:415px){*/
/*    .imgBack{*/
/*        background-size: contain;*/
/*    }*/
/*   */
/*}*/

.imgBack2{
    background-image: url('../../../Assets/myAsset/Group 139@2x.png');
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: contain;

    background-position-x: center;

}
@media(max-width:415px){
    .imgBack2{
        background-size: contain;
    }
   
}

