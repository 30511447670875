.container{
    display: flex;
    margin: 15px 5px ;
    flex-direction: row;
    border-radius: 5px;
    padding: 8px;
    box-shadow: 5px 5px 10px gray;
}
.addressWrapper{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.img{
    filter: drop-shadow(0 0 0 rgb(255, 94, 27)); 
    width: 30px;
}

.deleteWrapper{
    width: 30px;
}