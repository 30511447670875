.line{
    width: 20px;
    height: 1px;
    background-color: #ff5722;
    margin-left: 5px;
}
.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}
.NavigationItem a {
    color: #241a11;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}