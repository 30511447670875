.menuBx{
    width: 100%;
    height: 100%;
    background-color: blueviolet;
}

.SideDrawer {
    position: fixed;
    width: 100%;
    
    height: 100%;
    /* right: 0; */
    top: 0;
    z-index: 200;
    background-color: rgb(255, 255, 255);
    /* padding: 31px 0px 17px 17px; */
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}
.open{
    transform: translateX(0%);
}
.close{
    transform: translateX(500%);
}
