.btn{
  border-radius: 20px; 
  border: 2px solid #ff5722
}

.nBack{
    background-color: white;
    color: #ff5722;
    border: 2px solid #ff5722;
}

.disabled{
    background-color: #757373;
    color: white;
    border: 2px solid #757373;
}

/* .bordergradient {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
  }
  .bordergradientpurple {
    border-image-source: linear-gradient(to left, #ff403e, #fd8e26);
  } */