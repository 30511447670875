.container{
    height: calc(100vh - 130px);
}

.card{
    border-radius: 10px;
    box-shadow: 2px 3px 15px rgb(182, 179, 179);
    margin: 0px 0px 25px;
    width: 95%;
}
.img{
    width: 30px;
    border-radius: 50%;
}

.text{
    font-size: 13px;
}