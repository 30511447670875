.container{
    position: absolute;
    z-index: 1;
    top : 0;
    align-items: center;
    direction: rtl;
}

.input{
    /* border: 1px solid black !important;
    border-radius: 2px !important; */
}

