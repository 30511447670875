.lbl{
    border: 1px dotted;
    padding: 2%;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 0.3rem;
    cursor: pointer;
    float: right;
   
  }

  .badg {
    
    position: relative;
  }
  
  .badg::after {
    content:'';
    background-image:  url('../../Assets/myAsset/remove.svg');
    background-repeat: no-repeat;
    background-size: 15px ;
    display: inline-block;
    width: 15px; 
    height: 43px;
    content:"";
  }