.wrapper{
    width : 380px!important;
}

@media screen and (max-width: 450px) and (min-width: 400px){
    .wrapper{
        width : 330px !important;
    }
}

@media screen and (max-width: 400px){
    .wrapper{
        width : 300px !important;
    }
}