@import 'jalali-react-big-calendar/lib/css/react-big-calendar.css';
@import '../src/Components/Cal/calendar.css';
@import 'react-persian-datepicker/lib/styles/basic.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/************************************************/
@font-face {
  font-family: 'iranyekan';
  src: url('./Assets/fonts/iranyekanregular.woff') format('woff'),
    url('./Assets/fonts/iranyekanwebregular.eot') format('eot'),
    url('./Assets/fonts/iranyekanwebregular.svg') format('svg'),
    url('./Assets/fonts/iranyekanwebbold.ttf') format('opentype'),
    url('./Assets/fonts/iranyekanwebregular.ttf') format('ttf'),
    url('./Assets/fonts/iranyekanwebregular.woff2') format('woff2');
}

@font-face {
  font-family: 'Shabnam';
  src:
   /* url('./Assets/fonts/shabnam/Shabnam-Bold-WOL.eot') format('eot'),
    url('./Assets/fonts/shabnam/Shabnam-Bold-WOL.ttf') format('ttf'),
    url('./Assets/fonts/shabnam/Shabnam-Bold-WOL.woff') format('woff'),
    url('./Assets/fonts/shabnam/Shabnam-Light-WOL.eot') format('eot'),
    url('./Assets/fonts/shabnam/Shabnam-Light-WOL.ttf') format('ttf'),
    url('./Assets/fonts/shabnam/Shabnam-Light-WOL.woff') format('woff'),
    url('./Assets/fonts/shabnam/Shabnam-WOL.eot') format('eot'),
    url('./Assets/fonts/shabnam/Shabnam-WOL.woff') format('woff'), */
    url('./Assets/fonts/shabnam/Shabnam-WOL.woff') format('woff2'), 
}
@font-face {
  font-family: 'IRANSans';
  src: url('./Assets/fonts/shabnam/sans_Fanum_medium.ttf') format('woff2');
}
.fnt {
  font-family: 'IRANSans';
}
.rtl{
  direction: rtl;
}
.fntBold{
  font-weight: 600;
}
body{
  font-family: 'IRANSans';
}
.borderShadow {
  box-shadow: 0 0 4px 2px #cecece;
}
.borderShadow {
  box-shadow: 0 0 10px 1px #dcdada;
}
.customBorderR {
  border-radius: 16px;
}
.grey{
  color: #676767;
}
.smallF{
  font-size: 10px;
}
.mediumF{
  font-size: 14px;
}
.gradian{
  background: #ff403e; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #ff403e, #ff5d1c); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #ff403e, #ff5d1c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}


.single{
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(247, 247, 247);
  border-radius: 12px;
}
.backGreyLight{
  background-color: #F7F7F7;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* .badg {
    
  position: absolute;
  top:0;
  right:8px
}

.badg::after {
  content:'';
  background-image:  url('./Assets/myAsset/remove.svg');
  background-repeat: no-repeat;
  background-size: 15px ;
  display: inline-block;
  width: 15px; 
  height: 15px;
  content:"";
} */
.w3-green, .w3-hover-green:hover {
  color: #fff!important;
  background-color: #4CAF50!important;
}
.w3-badge {
  border-radius: 50%;
}
.w3-badge, .w3-tag {
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}


@media (min-height: 400px) and (max-height:600px) {

  .bxContent{
      max-height: 300px;
      overflow: auto;
  }

}
@media (min-height: 601px) and (max-height:700px) {

  .bxContent{
      max-height: 400px;
      overflow: auto;
  }

}
@media (min-height: 701px)  {

  .bxContent{
      max-height: 700px;
      overflow: auto;
  }

}
/***********************************************/

/**********************************************/

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.Button-badge {
  position: absolute;
  bottom: 80%;
  left: -5px;
  transform: translateX(8px);
  background-color: #ff5533;
  font-size: 10px;
  line-height: 0;
  font-weight: 500;
  color: #fff;
  padding: 1em 0.7em;
  border-radius: 100vh;
}