
.input {
    /* border: 0px solid #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; */
    font-size: 16px;
    background-color: transparent;
    
  }
  
  .fntLable{
    font-size: 10px;
  }
  input:focus {
    /* border-radius: 8px; */
    border: 0px solid #fff;
     outline: none;
     font-size: 16px;
  }
  .prefix{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 0px solid #fff;
    font-size: 16px;
  }