.card{
    border-radius: 10px;
    box-shadow: 2px 3px 15px rgb(182, 179, 179);
    margin: 0px 0px 25px;
}

.img{
    width: 30px;
    border-radius: 50%;
}
